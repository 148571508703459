import React from 'react';
import { graphql } from 'gatsby';
import ToolboxLayout from 'layouts/toolboxLayout';
import Hero from 'components/Tools/Hero';
import BCGraderResult from 'components/Tools/BusinessGrader/Result';
import STTestimonialCarousel from 'components/Sections/TestimonialCarousel/STTestimonialCarousel';

const GraderCalculatorPageResult = (props) => {
  const data = props.data.contentfulTool;
  const photoImg = props.data.contentfulImage;

  const formValues = props.location.state && props.location.state.values;
  const percentage =
    props.location.state && props.location.state.serviceTitanPercentageIncreaseHero;

  const allFeedbacks = [
    {
      title:
        '“ServiceTitan grew Bryant Electric’s revenue by 109%. It’s changed our business and it’s changed our life”',
      description: {
        description: '',
      },
      photo: photoImg.image,
      clientName: 'Jeff Bryant of Bryant Electric',
      companyName: 'ServiceTitan customer for 3 years',
    },
  ];

  data.seo.pageUrl += '-results';

  data.hero.heading = 'Here are your results!';
  const subheadContent = JSON.parse(data.hero.subhead.raw);
  if (subheadContent?.content[0]?.content[0]?.value) {
    subheadContent.content[0].content[0].value = `We estimate that your business could generate up to ${percentage}% more revenue per year. Click any recommendation below to see the potential impact on your business.`;
    data.hero.subhead = {
      ...data.hero.subhead,
      raw: JSON.stringify(subheadContent),
    };
  }

  return (
    <ToolboxLayout contentfulSeo={data.seo} layout="toolbox" {...props}>
      <Hero data={data.hero} noBreadCrumb />
      <BCGraderResult width="100%" formValues={formValues} />
      <STTestimonialCarousel
        type="descriptiononly"
        background="var(--skyline-1)"
        feedbacks={allFeedbacks}
      />
    </ToolboxLayout>
  );
};

export default GraderCalculatorPageResult;

export const GraderCalculatorPageResultQuery = graphql`
  query GraderCalculatorPageResultQuery {
    contentfulTool(internalName: { eq: "Service Business Grader" }) {
      ...Tool
    }
    contentfulImage(contentful_id: { eq: "1K4u6yyVbtkWXnOoSVfc5Q" }) {
      internalName
      image {
        file {
          url
        }
      }
    }
  }
`;
