import React, { useState } from 'react';
import NumericLabel from 'react-pretty-numbers';
import { graphql, useStaticQuery } from 'gatsby';
import HyperLink from 'components/Core/HyperLink';
import { GraderResultContainer } from '../styles';
import { FormContainer } from '../../styles';

const BCGraderResult = ({ width, formValues, ...otherProps }) => {
  const values = formValues || {
    Email: '',
    FirstName: '',
    LastName: '',
    Company: '',
    technicians: '',
    industry: '',
    otherIndustry: '',
    postalCode: '',
    Phone: '',
    averageTicketSize: '',
    completedJobs: '',
    utm_source: null,
    utm_medium: null,
    utm_campaign: null,
    utm_content: null,
    utm_term: null,
    utm_promo: null,
    gclid: '',
    creative: '',
    keyword: '',
    matchtype: '',
    scheduling: '',
    dispatch: '',
    tracking: '',
    estimates: '',
  };

  const data = useStaticQuery(graphql`
    query {
      allContentfulImage(
        filter: {
          contentful_id: {
            in: [
              "3WC0ZwL93Aj3MlvzngCoaW" #Illustration Dispatch
              "40D7A2WlomvmFScLT8Cmtg" #Illustration Estimates
              "5xvFhprJHAAj8N2gUJuRr2" #Illustration Scheduling
              "40lg2kye6OpyMva7r29Qyz" #Illustration Tracking
            ]
          }
        }
      ) {
        edges {
          node {
            internalName
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `);
  const pageImages = data.allContentfulImage;
  const STIllustrationDispatch =
    pageImages.edges
      .filter((imageItem) => imageItem.node.internalName === 'Illustration Dispatch')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const STIllustrationEstimates =
    pageImages.edges
      .filter((imageItem) => imageItem.node.internalName === 'Illustration Estimates')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const STIllustrationScheduling =
    pageImages.edges
      .filter((imageItem) => imageItem.node.internalName === 'Illustration Scheduling')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  const STIllustrationTracking =
    pageImages.edges
      .filter((imageItem) => imageItem.node.internalName === 'Illustration Tracking')
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';

  const [featuresEnabled, setFeaturesEnabled] = useState({
    scheduling: false,
    dispatch: false,
    tracking: false,
    estimates: false,
  });

  const schedulingValue = featuresEnabled['scheduling'] ? +values.scheduling : 0;
  const dispatchValue = featuresEnabled['dispatch'] ? +values.dispatch : 0;
  const trackingValue = featuresEnabled['tracking'] ? +values.tracking : 0;
  const estimatesValue = featuresEnabled['estimates'] ? +values.estimates : 0;

  const serviceTitanMultiplier =
    1 + (schedulingValue + dispatchValue + trackingValue + estimatesValue);
  const annualRevenue = values.technicians * values.averageTicketSize * values.completedJobs * 12;
  const annualRevenueST = annualRevenue * serviceTitanMultiplier;
  const monthlyRevenuePerTechnician = annualRevenue / values.technicians;
  const monthlyRevenuePerTechnicianST = monthlyRevenuePerTechnician * serviceTitanMultiplier;
  const serviceTitanPercentageIncrease = Math.floor(
    (schedulingValue + dispatchValue + trackingValue + estimatesValue) * 100,
  );

  const handleFeatureToggle = (featureName) => {
    setFeaturesEnabled({
      ...featuresEnabled,
      [featureName]: !featuresEnabled[featureName],
    });
  };

  const featuresEnabledArray = Object.values(featuresEnabled);
  const featureIsEnabled = featuresEnabledArray.some((feature) => feature);

  const IllustrationDispatch = {
    src: STIllustrationDispatch,
    alt: 'ServiceTitan Dispatch Product Illustration',
  };
  const IllustrationEstimates = {
    src: STIllustrationEstimates,
    alt: 'ServiceTitan Estimates Product Illustration',
  };
  const IllustrationScheduling = {
    src: STIllustrationScheduling,
    alt: 'ServiceTitan Scheduling Product Illustration',
  };
  const IllustrationTracking = {
    src: STIllustrationTracking,
    alt: 'ServiceTitan Tracking Product Illustration',
  };

  return (
    <FormContainer {...otherProps}>
      <div className="section-tool-page-form--wrapper">
        <div className="section-tool-page-form--container" style={{ maxWidth: '100%', padding: 0 }}>
          <GraderResultContainer>
            <div className="results--wrapper">
              <div className="results">
                <div className="result revenue">
                  <h6>Estimated annual revenue</h6>
                  <h2>
                    $
                    <NumericLabel params={{ shortFormat: true, precision: 2, justification: 'L' }}>
                      {annualRevenueST}
                    </NumericLabel>
                  </h2>
                  {featureIsEnabled && (
                    <h5>
                      Was: $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {annualRevenue}
                      </NumericLabel>{' '}
                      (+{serviceTitanPercentageIncrease}%)
                    </h5>
                  )}
                </div>
                <div className="result revenue-per-tech">
                  <h6>Monthly revenue per tech</h6>
                  <h2>
                    $
                    <NumericLabel params={{ shortFormat: true, precision: 1, justification: 'L' }}>
                      {monthlyRevenuePerTechnicianST}
                    </NumericLabel>
                  </h2>
                  {featureIsEnabled && (
                    <h5>
                      Was: $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {monthlyRevenuePerTechnician}
                      </NumericLabel>{' '}
                      (+{serviceTitanPercentageIncrease}%)
                    </h5>
                  )}
                </div>
              </div>
              <div className="features">
                <div
                  className={`feature scheduling ${
                    featuresEnabled['scheduling'] ? '' : 'disabled'
                  }`}
                >
                  <div className="add-feature" onClick={() => handleFeatureToggle('scheduling')}>
                    +
                  </div>
                  <div className="feature-description">
                    <h5>Scheduling</h5>
                    <p>
                      Recommendation: Get a bird's eye view into the health of your business with
                      ServiceTitan's Schedule Board. Quickly view booked and recurring jobs
                      using&hellip;
                    </p>
                    <HyperLink href="#scheduling">Learn more &rarr;</HyperLink>
                  </div>
                  <div className="revenue-increase">
                    <h5>
                      + $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {values.scheduling * annualRevenue}
                      </NumericLabel>
                    </h5>
                  </div>
                </div>
                <div
                  className={`feature dispatch ${featuresEnabled['dispatch'] ? '' : 'disabled'}`}
                >
                  <div className="add-feature" onClick={() => handleFeatureToggle('dispatch')}>
                    +
                  </div>
                  <div className="feature-description">
                    <h5>Dispatching</h5>
                    <p>
                      Recommendation: Intuitively dispatch your technicians based on their location
                      and skills with ServiceTitan's Dispatch Board. Assign techs designated zones
                      to reduce&hellip;
                    </p>
                    <HyperLink href="#dispatch">Learn more &rarr;</HyperLink>
                  </div>
                  <div className="revenue-increase">
                    <h5>
                      + $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {values.dispatch * annualRevenue}
                      </NumericLabel>
                    </h5>
                  </div>
                </div>
                <div
                  className={`feature estimates ${featuresEnabled['tracking'] ? '' : 'disabled'}`}
                >
                  <div className="add-feature" onClick={() => handleFeatureToggle('tracking')}>
                    +
                  </div>
                  <div className="feature-description">
                    <h5>Tracking</h5>
                    <p>
                      Recommendation: Keep tabs on your techs and delight your customers at the same
                      time with ServiceTitan's Arrival Tracking feature. With a simple tap on
                      their&hellip;
                    </p>
                    <HyperLink href="#tracking">Learn more &rarr;</HyperLink>
                  </div>
                  <div className="revenue-increase">
                    <h5>
                      + $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {values.tracking * annualRevenue}
                      </NumericLabel>
                    </h5>
                  </div>
                </div>
                <div
                  className={`feature estimates ${featuresEnabled['estimates'] ? '' : 'disabled'}`}
                >
                  <div className="add-feature" onClick={() => handleFeatureToggle('estimates')}>
                    +
                  </div>
                  <div className="feature-description">
                    <h5>Estimates</h5>
                    <p>
                      Recommendation: Upsell customers easier than ever before with ServiceTitan's
                      Online Estimates. Create sleek, mobile-responsive estimate templates that
                      techs&hellip;
                    </p>
                    <HyperLink href="#estimates">Learn more &rarr;</HyperLink>
                  </div>
                  <div className="revenue-increase">
                    <h5>
                      + $
                      <NumericLabel
                        params={{ shortFormat: true, precision: 1, justification: 'L' }}
                      >
                        {values.estimates * annualRevenue}
                      </NumericLabel>
                    </h5>
                  </div>
                </div>
              </div>
            </div>
            <h2 className="features--heading">Here's how ServiceTitan can help</h2>
            <div id="scheduling" className="feature--description">
              <div className="feature--container">
                <div className="feature--text-lockup">
                  <h2>Schedule Board</h2>
                  <p>
                    Get a bird's eye view into the health of your business with ServiceTitan's
                    Schedule Board. Quickly view booked and recurring jobs using customized filters
                    to get a quick overview of your month, week, or three-day period. Stop wasting
                    your time organizing your calendar and start proactively promoting your
                    business!
                  </p>
                  <HyperLink href="/service-scheduling-software">Learn more &rarr;</HyperLink>
                </div>
                <div className="feature--image">
                  <img src={IllustrationScheduling.src} alt={IllustrationScheduling.alt} />
                </div>
              </div>
            </div>
            <div id="dispatch" className="feature--description">
              <div className="feature--container">
                <div className="feature--image">
                  <img src={IllustrationDispatch.src} alt={IllustrationDispatch.alt} />
                </div>
                <div className="feature--text-lockup">
                  <h2>Dispatch Board</h2>
                  <p>
                    Intuitively dispatch your technicians based on their location and skills with
                    ServiceTitan's Dispatch Board. Assign techs designated zones to reduce travel
                    time and save on gas costs. Techs receive an in-app notification telling them
                    about their new job, keeping them on the road and your CSRs on the phone!
                  </p>
                  <HyperLink href="/features/dispatch-software">Learn more &rarr;</HyperLink>
                </div>
              </div>
            </div>
            <div id="tracking" className="feature--description">
              <div className="feature--container">
                <div className="feature--text-lockup">
                  <h2>Arrival Tracking</h2>
                  <p>
                    Keep tabs on your techs and delight your customers at the same time with
                    ServiceTitan's Arrival Tracking feature. With a simple tap on their tablet,
                    technicians can alert the customer that they're on their way. A GPS-enabled
                    tracking link allows the customer to track their technician's arrival in
                    real-time!
                  </p>
                  <HyperLink href="/features/dispatch-software">Learn more &rarr;</HyperLink>
                </div>
                <div className="feature--image">
                  <img src={IllustrationTracking.src} alt={IllustrationTracking.alt} />
                </div>
              </div>
            </div>
            <div id="estimates" className="feature--description">
              <div className="feature--container">
                <div className="feature--image">
                  <img src={IllustrationEstimates.src} alt={IllustrationEstimates.alt} />
                </div>
                <div className="feature--text-lockup">
                  <h2>Online Estimates</h2>
                  <p>
                    Upsell customers easier than ever before with ServiceTitan's Online Estimates.
                    Create sleek, mobile-responsive estimate templates that techs can easily
                    customize on-the-go. A follow-up tab allows both technicians and office staff to
                    easily follow-up on unsold estimates, tapping into new revenue opportunities!
                  </p>
                  <HyperLink href="/customer-experience-software">Learn more &rarr;</HyperLink>
                </div>
              </div>
            </div>
          </GraderResultContainer>
        </div>
      </div>
    </FormContainer>
  );
};

export default BCGraderResult;
