import styled from 'styled-components';

export const GraderFormContainer = styled.div`
  width: 100%;
  max-width: 930px;
  padding: 33px 30px;
  margin: 0 auto;
  background: ${(props) => props.background};
  box-shadow: ${(props) => (props.shadow ? '0px 20px 60px rgba(11, 26, 33, 0.15)' : 'none')};

  .averageTicketSize {
    color: black;
  }

  .signup-form--description {
    max-width: 685px;
    font-weight: var(--font-weight-500);
    font-size: var(--font-size-16);
    line-height: var(--line-height-150);
    text-align: right;
    color: var(--grey-8);
    margin: 0 auto 24px;
  }

  .signup-form--form {
    .form-field--container {
      max-width: 685px;
      margin: 0 auto 39px;
    }

    .radioButtonContainer {
      padding: 5px 0 5px 50px;

      .radioButton {
        top: -1px;
      }
    }
    .signup-form--submit-button {
      max-width: 685px;
      margin: 0 auto 39px;
      display: block;
      margin-top: 8px;
    }
  }

  @media (max-width: 767px) {
    padding: 24px 16px;

    .signup-form--title {
      font-family: var(--font-family-heading);
      font-size: var(--font-size-22);
      letter-spacing: var(--letter-spacing--0-02);
    }

    .signup-form--form {
      button {
        margin-top: 0;
      }
    }
  }

  @media (max-width: 575px) {
    padding: 20px 24px;

    .signup-form--form {
      .signup-form--submit-button {
        padding: 12px 24px;
        font-size: var(--font-size-16);
      }
    }
  }
`;

export const FormStep = styled.div`
  .form-progress {
    width: 100%;
    max-width: 685px;
    margin: 0 auto 60px;
    display: flex;
  }

  .form-step {
    width: calc(100% / 3);
    margin-right: 5px;
    height: 6px;
    border-radius: 3px;
    background: #e2f7e7;

    &:last-of-type {
      margin-right: 0;
    }
  }

  .form-step.active,
  .form-step.complete {
    background: #69b678;
  }
`;

export const GraderResultContainer = styled.div`
  .results {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    display: flex;
    position: sticky;
    position: -webkit-sticky;
    top: 100px;
  }

  .result {
    width: 50%;
    padding: 50px 0;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #002f44;

    &.revenue-per-tech {
      background: #002738;
    }

    h2 {
      color: var(--white);
      font-weight: var(--font-weight-800);
      font-size: var(--font-size-h1);
      letter-spacing: var(--letter-spacing--0-05);
      margin: 0;
      line-height: var(--line-height-100);
    }

    h5 {
      color: var(--grey-3);
      font-weight: var(--font-weight-500);
      font-size: var(--font-size-16);
      letter-spacing: var(--letter-spacing--0-01);
      margin-top: 8px;
      line-height: var(--line-height-100);
    }

    h6 {
      color: var(--titan-blue-2);
      font-weight: bold;
      font-size: var(--font-size-h5);
      text-align: center;
      letter-spacing: var(--letter-spacing--0-01);
    }
  }

  @media (max-width: 1399px) {
    .results {
      top: 80px;
    }
  }

  @media (max-width: 969px) {
    .result {
      padding: 30px 0;

      h2 {
        font-size: var(--font-size-35);
      }

      h6 {
        font-size: var(--font-size-16);
      }
    }
  }

  .features {
    width: 100%;
    max-width: 930px;
    margin: 0 auto 120px;
    box-shadow: 0px 20px 60px rgba(11, 26, 33, 0.15);
  }

  .feature {
    width: 100%;
    background: var(--white);
    padding: 50px;
    margin-bottom: 5px;
    display: flex;
    align-items: center;
    border-bottom: 4px solid #eee;

    &:last-of-type {
      border-bottom: none;
    }

    h5 {
      font-style: normal;
      font-weight: var(--font-weight-900);
      font-size: var(--font-size-25);
      line-height: var(--line-height-120);
      letter-spacing: var(--letter-spacing--0-02);
      color: var(--darkest);
    }

    p {
      font-style: normal;
      font-weight: normal;
      font-size: var(--font-size-16);
      line-height: var(--line-height-150);
      color: var(--grey-8);
    }

    a {
      font-style: normal;
      font-weight: var(--font-weight-700);
      font-size: var(--font-size-16);
      line-height: var(--line-height-110);
    }
  }

  .add-feature {
    width: 50px;
    height: 50px;
    background: var(--titan-blue-3);
    border-radius: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: var(--font-weight-900);
    font-size: var(--font-size-h3);
    letter-spacing: var(--letter-spacing--0-04);
    color: var(--white);
    cursor: pointer;
    transition: all 300ms ease;
    border: 3px solid var(--titan-blue-3);
    user-select: none;

    &:hover {
      background: var(--white);
      color: var(--titan-blue-3);
    }
  }

  .disabled .add-feature {
    background: var(--white);
    border: 3px solid var(--grey-3);
    color: var(--grey-3);

    &:hover {
      background: var(--white);
      color: var(--titan-blue-3);
    }
  }

  .feature-description {
    width: 60%;
    margin-left: 5%;
  }

  .revenue-increase {
    margin-left: auto;

    h5 {
      background: #87d9a3;
      height: 50px;
      line-height: 53px;
      border-radius: 25px;
      color: var(--white);
      padding: 0px 20px;
    }
  }

  .disabled .revenue-increase h5 {
    background: #ddd;
  }

  @media (max-width: 767px) {
    .feature {
      display: block;

      h5 {
        display: inline-block;
      }
    }

    .feature-description {
      width: 100%;
      margin: 25px 0%;
    }
  }

  .features--heading {
    font-style: normal;
    font-weight: var(--font-weight-900);
    font-size: var(--font-size-h2);
    text-align: center;
    letter-spacing: var(--letter-spacing--0-04);
    margin-bottom: -15px;
    line-height: var(--line-height-120);
    padding: 0 25px;
  }

  .feature--description {
    display: flex;
    padding: 75px 25px;

    &:nth-of-type(odd) {
      background: var(--skyline-1);
    }
  }

  .feature--container {
    width: 100%;
    max-width: 1200px;
    display: flex;
    margin: 0 auto;
  }

  .feature--text-lockup {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h2 {
      font-style: normal;
      font-weight: var(--font-weight-900);
      font-size: var(--font-size-35);
      line-height: var(--line-height-110);
      letter-spacing: var(--letter-spacing--0-04);
    }

    p {
      font-style: normal;
      font-weight: var(--font-weight-400);
      font-size: var(--font-size-20);
      line-height: var(--line-height-150);
      color: var(--dark);
    }
  }

  .feature--text-lockup a {
    font-style: normal;
    font-weight: bold;
    font-size: var(--font-size-18);
    line-height: var(--line-height-110);
    color: var(--titan-blue-3);
  }
  .feature--image {
    width: 55%;

    &:nth-of-type(even) {
      padding-left: 5%;
    }
    &:nth-of-type(odd) {
      padding-right: 5%;
    }

    img {
      width: 100%;
    }
  }

  @media (max-width: 767px) {
    .feature--text-lockup p {
      font-size: var(--font-size-16);
    }

    .feature--text-lockup h2 {
      font-size: var(--font-size-25);
    }
  }
  @media (max-width: 569px) {
    .features--heading {
      margin-bottom: 25px;
    }

    .features {
      margin-bottom: 80px;
    }

    .feature--container {
      flex-direction: column;
    }

    .feature--description {
      padding: 50px 25px 25px;
    }

    .feature--text-lockup {
      width: 100%;
    }

    .feature--image {
      order: 1;
      width: 100%;
      padding: 0 !important;
      margin-top: 50px;
    }
  }
`;
