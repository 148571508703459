import React from 'react';
import useDynamicHeroImages from 'hooks/useDynamicHeroImages';
import { HeroSubheadingWebsiteImage, SectionContainer } from '../styles';

const Hero = ({
  copy,
  data,
  subheadingImage,
  noBreadCrumb = false,
  noOverlap = false,
  backgroundImageName = 'bg-roi-01',
  ...otherProps
}) => {
  const { heading1, subhead, backgroundImage, background } = data;
  const pageImages = useDynamicHeroImages();
  const backgroundImg = backgroundImage?.image?.file?.url;
  const placeholderBackgroundImg =
    pageImages
      .filter((imageItem) => imageItem.node.internalName === backgroundImageName)
      .map((imageItem) => imageItem.node.image?.file?.url)[0] || '';
  return (
    <SectionContainer
      backgroundImg={backgroundImg}
      placeholderBackgroundImg={placeholderBackgroundImg}
      noBreadCrumb={noBreadCrumb}
      noOverlap={noOverlap}
      background={background}
      {...otherProps}
    >
      <div className="section-tool-page-hero--wrapper">
        <div className="section-tool-page-hero--container">
          {copy || (
            <>
              <h1 className="section-tool-page-hero--title capitalize">
                {JSON.parse(heading1.raw)?.content[0].content[0].value}
              </h1>
              {subhead?.raw && (
                <p className="section-tool-page-hero--description font-md">
                  {subheadingImage && (
                    <HeroSubheadingWebsiteImage
                      loading="eager"
                      image={subheadingImage?.image}
                      alt={subheadingImage?.alt}
                      className="section-tool-page-hero--subheading-image"
                    />
                  )}
                  {JSON.parse(subhead.raw)?.content[0].content[0].value}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </SectionContainer>
  );
};

export default Hero;
