import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Slider from 'react-slick';
import { colorTypeHelper } from 'utils/colorUtility';
import isNil from 'utils/isNil';
import WebsiteImage from 'components/WebsiteImage';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const STTestimonialCarousel = ({
  type,
  background,
  feedbacks,
  dots,
  arrows,
  titleColor,
  descriptionColor,
  ...otherProps
}) => {
  const backgroundColor = colorTypeHelper[background];

  const sliderSettings = {
    dots: dots,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: arrows,
    autoplay: true,
    autoplaySpeed: 5000,
    cssEase: 'cubic-bezier(0.55, 0.085, 0.68, 0.53)',
  };

  return (
    <SectionFeedback
      background={background}
      backgroundColor={backgroundColor}
      type={type}
      titleColor={titleColor}
      descriptionColor={descriptionColor}
      {...otherProps}
    >
      <div className="section-feedback--container">
        <div className="section-feedback--content-container">
          <Slider {...sliderSettings}>
            {feedbacks.map((feedback, index) => {
              return (
                <div className="section-feedback--feedback-item" key={index}>
                  <h2 className="section-feedback--title">{feedback.title}</h2>
                  <p className="section-feedback--description font-heading-lg">
                    {!isNil(feedback.description) ? feedback.description.description : ''}
                  </p>
                  <WebsiteImage
                    className="section-feedback--client-photo"
                    image={feedback.photo}
                    alt={feedback.photo.title}
                  />
                  <h2 className="section-feedback--client-name font-h4">{feedback.clientName}</h2>
                  <p className="section-feedback--client-company">{feedback.companyName}</p>
                  {type === 'descriptiononly' && feedback.employeeNum != '' && (
                    <p className="section-feedback--client-employeeNum">{feedback.employeeNum}</p>
                  )}
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
    </SectionFeedback>
  );
};

STTestimonialCarousel.propTypes = {
  type: PropTypes.string,
  feedbacks: PropTypes.array,
  background: PropTypes.string,
  dots: PropTypes.bool,
  titleColor: PropTypes.string,
  descriptionColor: PropTypes.string,
  arrows: PropTypes.bool,
};

STTestimonialCarousel.defaultProps = {
  type: 'normal',
  dots: true,
  arrows: false,
  titleColor: 'white',
  descriptionColor: 'white',
};

export default STTestimonialCarousel;

const SectionFeedback = styled.div`
  background: var(${(props) => props.backgroundColor});

  .section-feedback--container {
    max-width: 1192px;
    margin: 0 auto;

    .section-feedback--content-container {
      text-align: center;
      padding: 80px 20px;

      .section-feedback--feedback-item {
        max-width: 990px;
        text-align: center;

        .section-feedback--title {
          max-width: ${(props) => (props.type == 'descriptiononly' ? '100%' : '784px')};
          font-size: ${(props) => (props.type == 'descriptiononly' ? '39px' : '49px')};
          text-align: center;
          color: ${(props) =>
            props.type == 'descriptiononly' ? 'var(--darkest)' : props.titleColor};
          margin: 0 auto;
        }

        .section-feedback--description {
          text-align: center;
          color: ${(props) => props.descriptionColor};
          margin: 30px auto 0;
        }

        .section-feedback--client-photo {
          width: 70px;
          margin: 50px auto 0;
          border-radius: 50%;
        }

        .section-feedback--client-name {
          text-align: center;
          color: ${(props) =>
            props.type == 'descriptiononly' ? 'var(--darkest)' : props.titleColor};
          margin-top: 16px;
        }

        .section-feedback--client-company {
          text-align: center;
          color: ${(props) =>
            props.type == 'descriptiononly' ? 'var(--dark)' : props.descriptionColor};
          padding: 0;
          margin: 8px 0 0;
        }

        .section-feedback--client-employeeNum {
          font-weight: var(--font-weight-700);
          font-size: var(--font-size-16);
          line-height: 44px;
          text-align: center;
          color: var(--darkest);
          margin: 0;
          padding: 0;
        }
      }
    }
  }

  @media (max-width: 767px) {
    .section-feedback--container {
      .section-feedback--content-container {
        text-align: center;
        padding: 48px 28px;

        .section-feedback--feedback-item {
          max-width: 100%;
          text-align: center;

          .section-feedback--description {
            font-size: ${(props) => (props.type == 'descriptiononly' ? '32px' : '20px')};
            max-width: ${(props) => (props.type == 'descriptiononly' ? '100%' : '590px')};
            margin-top: 16px;
          }

          .section-feedback--client-photo {
            margin-top: 32px;
          }

          .section-feedback--title {
            font-size: var(--font-size-28);
          }

          .section-feedback--client-name {
            margin-top: 10px;
          }

          .section-feedback--client-company {
            margin-top: 8px;
          }

          .section-feedback--client-employeeNum {
            font-size: var(--font-size-13);
            line-height: 30px;
          }
        }
      }
    }
  }

  @media (max-width: 575px) {
    .section-feedback--container {
      .section-feedback--content-container {
        padding: 48px 20px;
      }
    }
  }

  .slick-slider {
    .slick-dots {
      position: relative;
      bottom: 0;
      margin-top: 20px;

      li {
        width: 46px;
        height: 6px;
        margin: 0 5px;
        padding: 0;

        button {
          width: 46px;
          height: 6px;
          padding: 0;
          margin: 0;
          background: var(--titan-blue-3);
          opacity: 0.2;

          &:before {
            content: none;
          }
        }

        &.slick-active {
          button {
            background: var(--titan-blue-2);
            opacity: 1;
          }
        }
      }
    }

    .slick-prev:before,
    .slick-next:before {
      border: solid var(--titan-blue-2);
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 9px;
    }

    .slick-prev {
      left: 0;
      z-index: 10;

      &:before {
        content: '';
        transform: rotate(135deg);
        -webkit-transform: rotate(135deg);
      }
    }

    .slick-next {
      right: 0;
      z-index: 10;

      &:before {
        content: '';
        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }

  @media (max-width: 767px) {
    .slick-slider {
      .slick-dots {
        margin-top: 30px;
      }

      .slick-arrow {
        display: none !important;
      }
    }
  }
`;
